body#home-page
  main
    background-color: $color-primary
  section
    position: relative
    z-index: 10
    background-color: $color-primary
    &#landing
      $height: clamp(0px, 45vw, 850px)
      height: $height
      overflow: hidden
      .wide-container
        height: 100%
        display: flex
        align-items: flex-end
      #landing-slider,
      #landing-video
        position: fixed
        top: $headerHeight
        left: 50%
        display: block
        width: clamp(0px, 100vw, 1920px)
        height: $height
        z-index: 0
        transform: translateX(-50%)
        video
          display: block
          width: 100%
          height: 100%
          object-fit: cover
        &.scrolled
          position: absolute
        .tns-outer,
        .tns-ovh,
        .tns-inner,
        .slider,
        .slide,
        .slide > .image
          height: 100%
      .title
        position: relative
        z-index: 2
        width: 100%
        margin-bottom: 0
      h1
        line-height: 100px

      .scroll-down
        position: absolute
        bottom: 10px
        left: $margin-md
        z-index: 20
        color: $color-light
        user-select: none

        .label
          transform: rotate(-90deg)
          transform-origin: left

        .arrow
          width: 12px
          margin-left: -4px
          svg
            display: block
            width: 100%
            height: auto
            animation: grow 6s linear infinite alternate
            path
              stroke: $color-light
    &#info
      padding-top: $margin-xxl
      padding-bottom: $margin-xxl
      span
        display: block

    &#new-model
      padding-top: $margin-md
      padding-bottom: $margin-xxl
      .container > .row
        gap: $margin-xl
        .left
          max-width: 500px
          flex: 2
        .right
          flex: 3
          .content
            padding-left: $margin-md
          .row
            gap: $spacing-md
          .tag
            background-color: $color-secondary
            padding: 4px $spacing-md

    &#lineup
      padding-top: $margin-xxl
      padding-bottom: 150px
      .grid
        display: grid
        grid-template-columns: 1fr 1fr 1fr
        gap: clamp(32px, 5vw, 120px)

@media screen and (max-width: $breakpoints-sm)
  body#home-page
    section
      &#landing
        .wide-container
          padding: 0
          & > .image
            top: $headerSpHeight
        #landing-slider
          top: $headerSpHeight
        .scroll-down
          display: none

      &#info
        padding-bottom: $margin-xxl
        .container > .row
          flex-direction: column
          .left
            .image
              max-width: 70%
          .right
            margin-top: $margin-xl
          .bottom
            left: auto
            right: 0
            bottom: auto
            transform: none
            top: 40%
            width: clamp(200px, 35vw, 450px)
      &#new-model
        .container > .row
          flex-direction: column
          align-items: center
          .left
            margin: auto
            .image
              width: 75%
              margin: auto
          .right
            h2
              font-size: $font-xxl
              text-align: center
            .content
              padding: 0
              margin-top: $margin-md
              text-align: center

              & > .row
                flex-direction: column
                align-items: center

      &#lineup
        padding-bottom: $margin-max

@media screen and (max-width: $breakpoints-xs)
  body#home-page
    section
      &#landing
        h1
          font-size: $font-xl
          line-height: $font-xl

      &#info
        .container > .row
          .left
            .image
              max-width: 60%
          .right
            margin-top: $margin-xl
          .bottom
            left: auto
            right: 0
            bottom: auto
            transform: none
            top: 15%
            width: 50%

      &#new-model
        .container > .row
          .left
            .image
              width: 60%
          .right
            h2
              font-size: $font-xl
            .content
              margin-top: $margin-sm


      &#lineup
        .grid
          grid-template-columns: 1fr
          .image
            max-width: 60%
            margin: auto
          .description
            text-align: center
