html
  font-size: 16px
  scroll-behavior: smooth
  body
    font-family: 'Quicksand', 'Marcellus', 'Noto Serif JP', Helvetica, sans-serif
    font-weight: 400
    color: $color-white
    background-color: $color-primary
    line-height: 1.6
    font-size: $font-rg

    main
      padding-top: $headerHeight

*::selection
  background-color: $color-secondary
  color: $color-light

a
  text-decoration: none
  color: inherit
  &:hover
    opacity: 0.85
ul
  margin: 0
  padding: 0
  list-style: none

svg
  path
    fill: currentColor

#scroll-top
  position: fixed
  bottom: 60px
  right: $margin-md
  z-index: 100
  color: $color-white
  animation: bump 4s linear infinite alternate
  user-select: none

  &:hover
    color: $color-light
    .arrow svg path
      stroke: $color-light

  .label
    writing-mode: vertical-lr
  .arrow
    width: 12px
    margin-left: 4px
    transform: rotate(180deg)
    svg
      display: block
      width: 100%
      height: auto
      path
        stroke: $color-light

.section-title
  position: relative
  padding-bottom: 8px
  &::before, &::after
    position: absolute
    content: ''
    bottom: 0px
    transform: translateX(-50%)
    height: 1px
    left: 50%
  &::before
    width: clamp(280px, 50%, 650px)
    background-color: $color-white
  &::after
    width: clamp(150px, 15%, 200%)
    background-color: $color-secondary
    z-index: 30

@media screen and (max-width: $breakpoints-lg)
  html
    font-size: 14px


@media screen and (max-width: $breakpoints-sm)
  html
    body
      main
        padding-top: $headerSpHeight
    #scroll-top
      right: $spacing-md
