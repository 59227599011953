@import './_burger.scss'

header
  height: 150px
  z-index: 100
  position: fixed
  top: 0
  left: 0
  right: 0
  background-color: #585858
  display: flex
  align-items: center
  .container
    width: 100%


  a.brand
    width: clamp(150px, 17vw, 325px)

  #toggle-nav, .burger-outer
    display: none

  nav
    gap: 100px

    .left
      gap: $margin-lg

    .link
      position: relative
      display: block
      padding: 0
      height: 100%
      text-align: center

      &::after
        content: ''
        position: absolute
        bottom: -4px
        left: 0
        width: 100%
        height: 2px
        background-color: $color-white
        border-radius: $radius-lg
        transform: scale(0)
        transition: 300ms ease-in-out
        transform-origin: left

      &.active
        // color: $color-secondary
        &::after
          background-color: $color-secondary

      &:hover, &.active
        &::after
          transform: scale(1)

@media screen and (max-width: $breakpoints-sm)
  $burgerSize: 44px
  $transition-duration: 0.5s

  header
    position: absolute
    background: none
    padding: $margin-sm 0
    z-index: 200
    height: $burgerSize

    .container
      align-items: flex-start

    #toggle-nav
      position: absolute
      &:checked
        & ~ nav
          transform: scale(1)
          opacity: 1

    .burger-outer
      position: fixed
      top: $margin-sm
      right: $margin-sm
      padding: 0
      width: $burgerSize
      height: $burgerSize
      box-sizing: border-box
      display: flex
      justify-content: center
      align-items: center
      background-color: rgba(255,255,255,0.75)
      border-radius: $radius-md
      margin-right: 0
      margin-left: auto
      z-index: 999
      .burger-container
        transform: scale(0.55)

    nav.row
      overflow: hidden
      position: fixed
      top: 0
      bottom: 0
      right: 0
      left: 0
      transform: scale(0)
      opacity: 0
      background-color: $color-primary
      color: $color-light
      transition: $transition-duration, opacity 0.1s
      transform-origin: center right
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      gap: $spacing-lg

      .left
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        gap: $spacing-lg

      .brand-sp
        display: block
        width: clamp(150px, 24vw, 325px)
        margin-bottom: $margin-xxl

      .link
        padding-left: 0
        font-size: $font-md

        &:after
          bottom: -2px

      .sns
        display: block
        & > .row
          align-self: center
          display: flex
          flex-direction: column
          margin-top: $margin-md
        .sns-links
          margin-top: $spacing-md
          gap: $margin-sm
          .sns-link
            height: 22px
            svg
              display: block
              height: 100%
              width: auto

