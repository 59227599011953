body#contact-page

  section
    position: relative
    z-index: 10
    background-color: $color-primary
    &#landing
      $height: clamp(200px, 22vw, 430px)
      height: $height
      overflow: hidden
      .main-container
        height: 100%
        display: flex
        align-items: flex-end
      .image
        display: block
        position: fixed
        top: $headerHeight
        left: 50%
        transform: translateX(-50%)
        width: clamp(0px, 100vw, 1920px)
        height: $height
        // object-fit: cover
        background-size: cover
        background-position: center
        background-repeat: no-repeat
        z-index: 0
        &.scrolled
          position: absolute
      .title
        position: relative
        z-index: 2
        width: 100%
        margin-bottom: 0
      h1
        line-height: 100px
        max-width: 100%

    &#contact
      padding-top: $margin-max
      .underline
        padding-bottom: 4px
        border-bottom: 1px solid $color-light

      form#contact_form
        max-width: clamp(300px, 75%, 800px)
        margin: $margin-xxl auto 0 auto
        padding-bottom: $margin-max
        text-align: center
        color: $color-green

        &.loading
          opacity: 0.8
          input, textarea, button
            pointer-events: none
          .loader
            display: inline-flex

        #form_error
          color: $color-red


        .input_group
          display: flex
          align-items: center
          margin-top: $margin-md
          color: $color-light
          label
            min-width: 150px
            text-align: left
          input, textarea
            width: 100%
            padding: $spacing-sm $spacing-md
            background-color: $color-grey
            border-radius: $radius-sm
            border: none
            color: $color-white
            &:active, &:focus
              outline-color: $color-secondary
          textarea
            resize: vertical

        button
          position: relative
          appearance: none
          outline: none
          border: none
          background-color: $color-grey
          color: $color-white
          width: 150px
          height: 42px
          border-radius: $radius-sm
          cursor: pointer
          transition: 300ms ease-in-out
          overflow: hidden

          &::before
            $size: 100%
            position: absolute
            content: ''
            background-color: $color-secondary
            bottom: 0
            left: 0
            width: $size
            height: $size
            max-height: 0
            max-width: 0
            transition: 300ms ease-in-out

          &:hover::before
            max-height: 100px
            max-width: 400px


          span
            position: relative
            z-index: 2

          .loader
            display: none
            position: absolute
            right: $spacing-md
            top: 50%
            transform: translateY(-50%)


@media screen and (max-width: $breakpoints-sm)
  body#contact-page
    section
      &#landing
        .wide-container
          padding: 0
          & > .image
            top: $headerSpHeight
      &#contact
        padding-top: $margin-xxl

        p.text,
        .contact_hours,
        .form_title,
        form#contact_form,
        .submit
          margin-top: $margin-lg

        form#contact_form
          .input_group
            label
              min-width: 130px


@media screen and (max-width: $breakpoints-xs)
  body#contact-page
    section
      &#landing
        h1
          font-size: $font-xxl
          line-height: $font-xxl

      &#contact
        padding-top: $margin-lg

        p.text,
        .contact_hours,
        .form_title,
        form#contact_form,
        .submit
          margin-top: $margin-md

        form#contact_form
          padding-bottom: $margin-xl
          .input_group
            flex-direction: column
            align-items: flex-start
            label
              min-width: 130px
            input, textarea
              width: auto
              align-self: stretch

