body#product-page
  .image-notice
    padding-right: $spacing-lg
  main
    background-color: $color-primary
  section
    position: relative
    z-index: 10
    background-color: $color-primary
    &#landing
      $height: clamp(0px, 50vw, 870px)
      height: $height
      overflow: hidden
      .main-container
        height: 100%
        display: flex
        align-items: flex-end
      #landing-bg
        display: block
        position: fixed
        top: $headerHeight
        left: 50%
        transform: translateX(-50%)
        width: clamp(0px, 100vw, 1920px)
        height: $height
        z-index: 0
        .image
          width: 100%
          height: 100%
        &.scrolled
          position: absolute
      .title
        position: relative
        z-index: 2
        width: 100%
        margin-bottom: 0
      h1
        line-height: 100px
        max-width: 100%

      .scroll-down
        position: absolute
        bottom: 10px
        left: $margin-md
        z-index: 20
        color: $color-light
        user-select: none

        .label
          transform: rotate(-90deg)
          transform-origin: left

        .arrow
          width: 12px
          margin-left: -4px
          svg
            display: block
            width: 100%
            height: auto
            animation: grow 6s linear infinite alternate
            path
              stroke: $color-light

    &#info,
    &#details,
    &#specs,
    &#gallery
      padding-top: $margin-max

    &#info
      .row
        gap: $margin-lg
      .left
        flex: 2
        .image
          width: 80%
          margin: auto
      .right
        flex: 3
        .logo
          width: clamp(200px, 75%, 500px)
          margin-top: $margin-xxl
          margin-left: auto
    &#details
      .row
        &:nth-child(odd)
          flex-direction: row-reverse
        .left
          flex: 7
          p
            width: clamp(280px, 35vw, 640px)
            margin: auto
        .right
          flex: 6

      .image
        // $height: clamp(100px, 33vw, 450px)
        // $width: clamp(300px, 100vw, 1920px)
        width: 100%
        height: 450px
        position: relative
        overflow: hidden
        img
          position: absolute
          top: 0
          left: 0
          width: 100%
          height: 100%
          object-fit: cover
          object-position: center
    &#specs
      .red
        color: $color-red
      .grey
        color: $color-grey
      .specs
        overflow-x: auto
        table, th, td
          border: 1px solid $color-light
          white-space: nowrap
        table
          border-collapse: collapse
          background-color: $color-white
          color: $color-primary
          thead
            background-color: $color-light
          th, td
            padding: 4px $spacing-md
          th
            position: sticky
            top: 0
            z-index: 9999999999
          td
            &.comment
              text-align: left
          .accent
            background-color: $color-secondary
            color: $color-white
      .info
        .row
          gap: $margin-md
          row-gap: $spacing-xs
        .info-item
          line-height: 1.9
          &::before
            margin-right: $spacing-xs
        .square
          &::before
            content: '■'
        .circle
          &::before
            content: '●'
        .notice
          &::before
            content: '※'
    &#gallery
      padding-bottom: $margin-max
      .gallery-grid
        display: grid
        grid-template-columns: 1fr 1fr 1fr
        gap: $margin-xl
        .image
          cursor: pointer
          overflow: hidden
          width: 80%
          max-height: 280px
          margin: auto
          &:hover
            opacity: 0.85
            img
              transform: scale(1)
          img
            transition: 300ms ease-in-out
            transform: scale(1.05)

  #gallery-modal
    position: fixed
    top: 0
    left: 0
    height: 100vh
    width: 100vw
    z-index: 9999999999
    color: $color-white
    justify-content: center
    align-items: center
    display: flex
    visibility: hidden
    &.opened
      visibility: visible
      .inner
        transform: scale(1)
        opacity: 1
        transition: 300ms ease-in-out
    .outer
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
      background-color: rgba($color-primary, 0.8)
    .inner
      position: relative
      z-index: 9999
      width: clamp(300px, 75%, 1100px)
      height: clamp(420px, 85%, 1100px)
      display: flex
      justify-content: center
      align-items: center
      opacity: 0
      transform: scale(.25)
      img
        max-height: 100%
        max-width: 100%
        margin: auto
        object-fit: cover
        object-position: center
    #close-modal
      position: absolute
      top: 10vh
      right: 10vw
      width: 40px
      z-index: 99999
      appearance: none
      outline: none
      border: none
      background: transparent
      color: inherit
      margin: 0
      padding: 0
      cursor: pointer
      background-color: rgba($color-primary, 0.5)
      border-radius: 50%
      svg
        display: block
        width: 100%
        height: auto
        path
          fill: currentColor

@media screen and (max-width: $breakpoints-sm)
  body#product-page
    section
      &#landing
        .wide-container
          padding: 0
          & > #landing-bg
            top: $headerSpHeight
        .scroll-down
          display: none

      &#info
        .right
          .logo
            width: clamp(250px, 50%, 400px)
            margin-top: $margin-lg
            margin-right: $margin-lg

      &#details
        .detail-row
          flex-direction: column-reverse!important
          margin-top: $margin-md
          .left
            margin-top: $margin-md
            h3
              margin-bottom: $font-sm

      &#gallery
        .gallery-grid
          margin-top: $margin-md
          grid-template-columns: 1fr 1fr
          gap: $margin-sm

    #gallery-modal
      .inner
        #close-modal
          width: 32px

@media screen and (max-width: $breakpoints-xs)
  body#product-page
    section
      &#landing
        h1
          font-size: $font-xxl
          line-height: $font-xxl

      &#info,
      &#details,
      &#specs,
      &#gallery
        padding-top: $margin-xl

      &#info
        & > .row
          flex-direction: column
        .left
          .image
            width: 70%

        .right
          h3
            text-align: center
            font-size: 1.25rem
          .logo
            width: clamp(200px, 50%, 400px)
            margin-top: $margin-md
            margin-right: auto
            margin-left: auto

      &#details
        .image
          // $height: clamp(100px, 33vw, 450px)
          // $width: clamp(300px, 100vw, 1920px)
          height: 40vh

      &#gallery
        padding-bottom: $margin-xl
        .gallery-grid
          margin-top: $margin-lg
          grid-template-columns: 1fr 1fr
          gap: $margin-sm
