@use 'css/_normalize.scss'

@import 'css/_variables.sass'
@import 'css/_typography.sass'
@import 'css/_helpers.sass'
@import 'css/_loaders.sass'
@import 'css/_buttons.sass'
@import 'css/_animations.sass'
@import 'css/_common.sass'

@import 'css/pages/_home.sass'
@import 'css/pages/_product.sass'
@import 'css/pages/_contact.sass'

@import 'css/sections/_header.sass'
@import 'css/sections/_breadcrumbs.sass'
@import 'css/sections/_footer.sass'
