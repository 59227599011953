html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

.text-bold {
  font-weight: 700;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-vertical {
  writing-mode: vertical-rl;
}

.text-primary {
  color: #191919;
}

.text-secondary {
  color: #753235;
}

.text-white {
  color: #fff;
}

.text-light {
  color: #e9e8e8;
}

.font-xs {
  font-size: .75rem;
}

.font-sm {
  font-size: .875rem;
}

.font-rg {
  font-size: 1rem;
}

.font-md {
  font-size: 1.3125rem;
}

.font-lg {
  font-size: 2rem;
}

.font-xl {
  font-size: 2.5rem;
}

.font-xxl {
  font-size: 3.125rem;
}

.font-max {
  font-size: 7rem;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.3125rem;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: normal;
  line-height: 1.55;
}

p {
  line-height: 1.7;
}

@media screen and (max-width: 768px) {
  .font-lg {
    font-size: 1.5rem;
  }

  .font-xl {
    font-size: 2.5rem;
  }
}

.wide-container, .container {
  box-sizing: border-box;
  margin: auto;
  padding: 0 12px;
  position: relative;
}

.wide-container {
  max-width: 1944px;
}

.container {
  max-width: 1304px;
}

.bg-secondary {
  background-color: #d49c8e1a;
}

.hidden {
  display: none;
}

.inline {
  display: inline;
}

.block {
  display: block;
}

.inline-flex {
  display: inline-flex;
}

.row {
  display: flex;
}

.column {
  flex-direction: column;
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.img-responsive-x {
  max-width: 100%;
  height: auto;
  display: block;
}

.img-responsive-y {
  max-height: 100%;
  width: auto;
  display: block;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}

.img-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  display: block;
}

.radius-xs {
  border-radius: 2px;
}

.radius-sm {
  border-radius: 4px;
}

.radius-md {
  border-radius: 8px;
}

.radius-lg {
  border-radius: 16px;
}

.radius-xl {
  border-radius: 32px;
}

.radius-round {
  border-radius: 50%;
}

.m-xs {
  margin: 10px;
}

.m-sm {
  margin: 20px;
}

.m-md {
  margin: 30px;
}

.m-lg {
  margin: 40px;
}

.m-xl {
  margin: 50px;
}

.m-xxl {
  margin: 60px;
}

.m-max {
  margin: 100px;
}

.mx-xs {
  margin-left: 10px;
  margin-right: 10px;
}

.mx-sm {
  margin-left: 20px;
  margin-right: 20px;
}

.mx-md {
  margin-left: 30px;
  margin-right: 30px;
}

.mx-lg {
  margin-left: 40px;
  margin-right: 40px;
}

.mx-xl {
  margin-left: 50px;
  margin-right: 50px;
}

.mx-xxl {
  margin-left: 60px;
  margin-right: 60px;
}

.mx-max {
  margin-left: 100px;
  margin-right: 100px;
}

.my-xs {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-sm {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-md {
  margin-top: 30px;
  margin-bottom: 30px;
}

.my-lg {
  margin-top: 40px;
  margin-bottom: 40px;
}

.my-xl {
  margin-top: 50px;
  margin-bottom: 50px;
}

.my-xxl {
  margin-top: 60px;
  margin-bottom: 60px;
}

.my-max {
  margin-top: 100px;
  margin-bottom: 100px;
}

.mt-xs {
  margin-top: 10px;
}

.mt-sm {
  margin-top: 20px;
}

.mt-md {
  margin-top: 30px;
}

.mt-lg {
  margin-top: 40px;
}

.mt-xl {
  margin-top: 50px;
}

.mt-xxl {
  margin-top: 60px;
}

.mt-max {
  margin-top: 100px;
}

.mb-xs {
  margin-bottom: 10px;
}

.mb-sm {
  margin-bottom: 20px;
}

.mb-md {
  margin-bottom: 30px;
}

.mb-lg {
  margin-bottom: 40px;
}

.mb-xl {
  margin-bottom: 50px;
}

.mb-xxl {
  margin-bottom: 60px;
}

.mb-max {
  margin-bottom: 100px;
}

.ml-xs {
  margin-left: 10px;
}

.ml-sm {
  margin-left: 20px;
}

.ml-md {
  margin-left: 30px;
}

.ml-lg {
  margin-left: 40px;
}

.ml-xl {
  margin-left: 50px;
}

.ml-xxl {
  margin-left: 60px;
}

.ml-max {
  margin-left: 100px;
}

.mr-xs {
  margin-right: 10px;
}

.mr-sm {
  margin-right: 20px;
}

.mr-md {
  margin-right: 30px;
}

.mr-lg {
  margin-right: 40px;
}

.mr-xl {
  margin-right: 50px;
}

.mr-xxl {
  margin-right: 60px;
}

.mr-max {
  margin-right: 100px;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 20px;
  }
}

@media screen and (max-width: 576px) {
  .container {
    padding: 0 10px;
  }
}

.loader {
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.loader div {
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border: 2px solid #0000;
  border-top-color: #fff;
  border-radius: 50%;
  margin: 8px;
  animation: 1.4s cubic-bezier(.5, 0, .5, 1) infinite loader;
  display: block;
  position: absolute;
}

.loader div:nth-child(1) {
  animation-delay: -.45s;
}

.loader div:nth-child(2) {
  animation-delay: -.3s;
}

.loader div:nth-child(3) {
  animation-delay: -.15s;
}

@keyframes loader {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rollin {
  0% {
    transform: translateX(100vw)rotate(720deg);
  }

  100% {
    transform: translateX(0)rotate(0);
  }
}

@keyframes bump {
  0% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes grow {
  0% {
    transform-origin: top;
    transform: scale(1, .25);
  }

  100% {
    transform: scale(1);
  }
}

.rollout {
  transform: translateX(100vw)rotate(720deg);
}

.rollin {
  transform: translateX(0)rotate(0);
}

.fadeout {
  opacity: 0;
  transform: translateY(60px);
}

.fadein {
  opacity: 1;
  transform: translateY(0);
}

[data-animation="fadein"] {
  transition: all .8s ease-in;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

html body {
  color: #fff;
  background-color: #191919;
  font-family: Quicksand, Marcellus, Noto Serif JP, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
}

html body main {
  padding-top: 150px;
}

::selection {
  color: #e9e8e8;
  background-color: #753235;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  opacity: .85;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

svg path {
  fill: currentColor;
}

#scroll-top {
  z-index: 100;
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  animation: 4s linear infinite alternate bump;
  position: fixed;
  bottom: 60px;
  right: 30px;
}

#scroll-top:hover {
  color: #e9e8e8;
}

#scroll-top:hover .arrow svg path {
  stroke: #e9e8e8;
}

#scroll-top .label {
  writing-mode: vertical-lr;
}

#scroll-top .arrow {
  width: 12px;
  margin-left: 4px;
  transform: rotate(180deg);
}

#scroll-top .arrow svg {
  width: 100%;
  height: auto;
  display: block;
}

#scroll-top .arrow svg path {
  stroke: #e9e8e8;
}

.section-title {
  padding-bottom: 8px;
  position: relative;
}

.section-title:before, .section-title:after {
  content: "";
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.section-title:before {
  width: clamp(280px, 50%, 650px);
  background-color: #fff;
}

.section-title:after {
  width: max(150px, 15%);
  z-index: 30;
  background-color: #753235;
}

@media screen and (max-width: 1280px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  html body main {
    padding-top: 84px;
  }

  html #scroll-top {
    right: 16px;
  }
}

body#home-page main {
  background-color: #191919;
}

body#home-page section {
  z-index: 10;
  background-color: #191919;
  position: relative;
}

body#home-page section#landing {
  height: clamp(0px, 45vw, 850px);
  overflow: hidden;
}

body#home-page section#landing .wide-container {
  height: 100%;
  align-items: flex-end;
  display: flex;
}

body#home-page section#landing #landing-slider, body#home-page section#landing #landing-video {
  width: clamp(0px, 100vw, 1920px);
  height: clamp(0px, 45vw, 850px);
  z-index: 0;
  display: block;
  position: fixed;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
}

body#home-page section#landing #landing-slider video, body#home-page section#landing #landing-video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

body#home-page section#landing #landing-slider.scrolled, body#home-page section#landing #landing-video.scrolled {
  position: absolute;
}

body#home-page section#landing #landing-slider .tns-outer, body#home-page section#landing #landing-slider .tns-ovh, body#home-page section#landing #landing-slider .tns-inner, body#home-page section#landing #landing-slider .slider, body#home-page section#landing #landing-slider .slide, body#home-page section#landing #landing-slider .slide > .image, body#home-page section#landing #landing-video .tns-outer, body#home-page section#landing #landing-video .tns-ovh, body#home-page section#landing #landing-video .tns-inner, body#home-page section#landing #landing-video .slider, body#home-page section#landing #landing-video .slide, body#home-page section#landing #landing-video .slide > .image {
  height: 100%;
}

body#home-page section#landing .title {
  z-index: 2;
  width: 100%;
  margin-bottom: 0;
  position: relative;
}

body#home-page section#landing h1 {
  line-height: 100px;
}

body#home-page section#landing .scroll-down {
  z-index: 20;
  color: #e9e8e8;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  bottom: 10px;
  left: 30px;
}

body#home-page section#landing .scroll-down .label {
  transform-origin: 0;
  transform: rotate(-90deg);
}

body#home-page section#landing .scroll-down .arrow {
  width: 12px;
  margin-left: -4px;
}

body#home-page section#landing .scroll-down .arrow svg {
  width: 100%;
  height: auto;
  animation: 6s linear infinite alternate grow;
  display: block;
}

body#home-page section#landing .scroll-down .arrow svg path {
  stroke: #e9e8e8;
}

body#home-page section#info {
  padding-top: 60px;
  padding-bottom: 60px;
}

body#home-page section#info span {
  display: block;
}

body#home-page section#new-model {
  padding-top: 30px;
  padding-bottom: 60px;
}

body#home-page section#new-model .container > .row {
  gap: 50px;
}

body#home-page section#new-model .container > .row .left {
  max-width: 500px;
  flex: 2;
}

body#home-page section#new-model .container > .row .right {
  flex: 3;
}

body#home-page section#new-model .container > .row .right .content {
  padding-left: 30px;
}

body#home-page section#new-model .container > .row .right .row {
  gap: 16px;
}

body#home-page section#new-model .container > .row .right .tag {
  background-color: #753235;
  padding: 4px 16px;
}

body#home-page section#lineup {
  padding-top: 60px;
  padding-bottom: 150px;
}

body#home-page section#lineup .grid {
  grid-template-columns: 1fr 1fr 1fr;
  gap: clamp(32px, 5vw, 120px);
  display: grid;
}

@media screen and (max-width: 768px) {
  body#home-page section#landing .wide-container {
    padding: 0;
  }

  body#home-page section#landing .wide-container > .image, body#home-page section#landing #landing-slider {
    top: 84px;
  }

  body#home-page section#landing .scroll-down {
    display: none;
  }

  body#home-page section#info {
    padding-bottom: 60px;
  }

  body#home-page section#info .container > .row {
    flex-direction: column;
  }

  body#home-page section#info .container > .row .left .image {
    max-width: 70%;
  }

  body#home-page section#info .container > .row .right {
    margin-top: 50px;
  }

  body#home-page section#info .container > .row .bottom {
    width: clamp(200px, 35vw, 450px);
    inset: 40% 0 auto auto;
    transform: none;
  }

  body#home-page section#new-model .container > .row {
    flex-direction: column;
    align-items: center;
  }

  body#home-page section#new-model .container > .row .left {
    margin: auto;
  }

  body#home-page section#new-model .container > .row .left .image {
    width: 75%;
    margin: auto;
  }

  body#home-page section#new-model .container > .row .right h2 {
    text-align: center;
    font-size: 3.125rem;
  }

  body#home-page section#new-model .container > .row .right .content {
    text-align: center;
    margin-top: 30px;
    padding: 0;
  }

  body#home-page section#new-model .container > .row .right .content > .row {
    flex-direction: column;
    align-items: center;
  }

  body#home-page section#lineup {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 576px) {
  body#home-page section#landing h1 {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  body#home-page section#info .container > .row .left .image {
    max-width: 60%;
  }

  body#home-page section#info .container > .row .right {
    margin-top: 50px;
  }

  body#home-page section#info .container > .row .bottom {
    width: 50%;
    inset: 15% 0 auto auto;
    transform: none;
  }

  body#home-page section#new-model .container > .row .left .image {
    width: 60%;
  }

  body#home-page section#new-model .container > .row .right h2 {
    font-size: 2.5rem;
  }

  body#home-page section#new-model .container > .row .right .content {
    margin-top: 20px;
  }

  body#home-page section#lineup .grid {
    grid-template-columns: 1fr;
  }

  body#home-page section#lineup .grid .image {
    max-width: 60%;
    margin: auto;
  }

  body#home-page section#lineup .grid .description {
    text-align: center;
  }
}

body#product-page .image-notice {
  padding-right: 20px;
}

body#product-page main {
  background-color: #191919;
}

body#product-page section {
  z-index: 10;
  background-color: #191919;
  position: relative;
}

body#product-page section#landing {
  height: clamp(0px, 50vw, 870px);
  overflow: hidden;
}

body#product-page section#landing .main-container {
  height: 100%;
  align-items: flex-end;
  display: flex;
}

body#product-page section#landing #landing-bg {
  width: clamp(0px, 100vw, 1920px);
  height: clamp(0px, 50vw, 870px);
  z-index: 0;
  display: block;
  position: fixed;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
}

body#product-page section#landing #landing-bg .image {
  width: 100%;
  height: 100%;
}

body#product-page section#landing #landing-bg.scrolled {
  position: absolute;
}

body#product-page section#landing .title {
  z-index: 2;
  width: 100%;
  margin-bottom: 0;
  position: relative;
}

body#product-page section#landing h1 {
  max-width: 100%;
  line-height: 100px;
}

body#product-page section#landing .scroll-down {
  z-index: 20;
  color: #e9e8e8;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  bottom: 10px;
  left: 30px;
}

body#product-page section#landing .scroll-down .label {
  transform-origin: 0;
  transform: rotate(-90deg);
}

body#product-page section#landing .scroll-down .arrow {
  width: 12px;
  margin-left: -4px;
}

body#product-page section#landing .scroll-down .arrow svg {
  width: 100%;
  height: auto;
  animation: 6s linear infinite alternate grow;
  display: block;
}

body#product-page section#landing .scroll-down .arrow svg path {
  stroke: #e9e8e8;
}

body#product-page section#info, body#product-page section#details, body#product-page section#specs, body#product-page section#gallery {
  padding-top: 100px;
}

body#product-page section#info .row {
  gap: 40px;
}

body#product-page section#info .left {
  flex: 2;
}

body#product-page section#info .left .image {
  width: 80%;
  margin: auto;
}

body#product-page section#info .right {
  flex: 3;
}

body#product-page section#info .right .logo {
  width: clamp(200px, 75%, 500px);
  margin-top: 60px;
  margin-left: auto;
}

body#product-page section#details .row:nth-child(2n+1) {
  flex-direction: row-reverse;
}

body#product-page section#details .row .left {
  flex: 7;
}

body#product-page section#details .row .left p {
  width: clamp(280px, 35vw, 640px);
  margin: auto;
}

body#product-page section#details .row .right {
  flex: 6;
}

body#product-page section#details .image {
  width: 100%;
  height: 450px;
  position: relative;
  overflow: hidden;
}

body#product-page section#details .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
}

body#product-page section#specs .red {
  color: #912f3c;
}

body#product-page section#specs .grey {
  color: #7e807b;
}

body#product-page section#specs .specs {
  overflow-x: auto;
}

body#product-page section#specs .specs table, body#product-page section#specs .specs th, body#product-page section#specs .specs td {
  white-space: nowrap;
  border: 1px solid #e9e8e8;
}

body#product-page section#specs .specs table {
  border-collapse: collapse;
  color: #191919;
  background-color: #fff;
}

body#product-page section#specs .specs table thead {
  background-color: #e9e8e8;
}

body#product-page section#specs .specs table th, body#product-page section#specs .specs table td {
  padding: 4px 16px;
}

body#product-page section#specs .specs table th {
  z-index: 2147483647;
  position: sticky;
  top: 0;
}

body#product-page section#specs .specs table td.comment {
  text-align: left;
}

body#product-page section#specs .specs table .accent {
  color: #fff;
  background-color: #753235;
}

body#product-page section#specs .info .row {
  gap: 10px 30px;
}

body#product-page section#specs .info .info-item {
  line-height: 1.9;
}

body#product-page section#specs .info .info-item:before {
  margin-right: 10px;
}

body#product-page section#specs .info .square:before {
  content: "■";
}

body#product-page section#specs .info .circle:before {
  content: "●";
}

body#product-page section#specs .info .notice:before {
  content: "※";
}

body#product-page section#gallery {
  padding-bottom: 100px;
}

body#product-page section#gallery .gallery-grid {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
  display: grid;
}

body#product-page section#gallery .gallery-grid .image {
  cursor: pointer;
  width: 80%;
  max-height: 280px;
  margin: auto;
  overflow: hidden;
}

body#product-page section#gallery .gallery-grid .image:hover {
  opacity: .85;
}

body#product-page section#gallery .gallery-grid .image:hover img {
  transform: scale(1);
}

body#product-page section#gallery .gallery-grid .image img {
  transition: all .3s ease-in-out;
  transform: scale(1.05);
}

body#product-page #gallery-modal {
  height: 100vh;
  width: 100vw;
  z-index: 2147483647;
  color: #fff;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

body#product-page #gallery-modal.opened {
  visibility: visible;
}

body#product-page #gallery-modal.opened .inner {
  opacity: 1;
  transition: all .3s ease-in-out;
  transform: scale(1);
}

body#product-page #gallery-modal .outer {
  background-color: #191919cc;
  position: absolute;
  inset: 0;
}

body#product-page #gallery-modal .inner {
  z-index: 9999;
  width: clamp(300px, 75%, 1100px);
  height: clamp(420px, 85%, 1100px);
  opacity: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  transform: scale(.25);
}

body#product-page #gallery-modal .inner img {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
  margin: auto;
}

body#product-page #gallery-modal #close-modal {
  width: 40px;
  z-index: 99999;
  appearance: none;
  color: inherit;
  cursor: pointer;
  background: #19191980;
  border: none;
  border-radius: 50%;
  outline: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 10vh;
  right: 10vw;
}

body#product-page #gallery-modal #close-modal svg {
  width: 100%;
  height: auto;
  display: block;
}

body#product-page #gallery-modal #close-modal svg path {
  fill: currentColor;
}

@media screen and (max-width: 768px) {
  body#product-page section#landing .wide-container {
    padding: 0;
  }

  body#product-page section#landing .wide-container > #landing-bg {
    top: 84px;
  }

  body#product-page section#landing .scroll-down {
    display: none;
  }

  body#product-page section#info .right .logo {
    width: clamp(250px, 50%, 400px);
    margin-top: 40px;
    margin-right: 40px;
  }

  body#product-page section#details .detail-row {
    margin-top: 30px;
    flex-direction: column-reverse !important;
  }

  body#product-page section#details .detail-row .left {
    margin-top: 30px;
  }

  body#product-page section#details .detail-row .left h3 {
    margin-bottom: .875rem;
  }

  body#product-page section#gallery .gallery-grid {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 30px;
  }

  body#product-page #gallery-modal .inner #close-modal {
    width: 32px;
  }
}

@media screen and (max-width: 576px) {
  body#product-page section#landing h1 {
    font-size: 3.125rem;
    line-height: 3.125rem;
  }

  body#product-page section#info, body#product-page section#details, body#product-page section#specs, body#product-page section#gallery {
    padding-top: 50px;
  }

  body#product-page section#info > .row {
    flex-direction: column;
  }

  body#product-page section#info .left .image {
    width: 70%;
  }

  body#product-page section#info .right h3 {
    text-align: center;
    font-size: 1.25rem;
  }

  body#product-page section#info .right .logo {
    width: clamp(200px, 50%, 400px);
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  body#product-page section#details .image {
    height: 40vh;
  }

  body#product-page section#gallery {
    padding-bottom: 50px;
  }

  body#product-page section#gallery .gallery-grid {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 40px;
  }
}

body#contact-page section {
  z-index: 10;
  background-color: #191919;
  position: relative;
}

body#contact-page section#landing {
  height: clamp(200px, 22vw, 430px);
  overflow: hidden;
}

body#contact-page section#landing .main-container {
  height: 100%;
  align-items: flex-end;
  display: flex;
}

body#contact-page section#landing .image {
  width: clamp(0px, 100vw, 1920px);
  height: clamp(200px, 22vw, 430px);
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  position: fixed;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
}

body#contact-page section#landing .image.scrolled {
  position: absolute;
}

body#contact-page section#landing .title {
  z-index: 2;
  width: 100%;
  margin-bottom: 0;
  position: relative;
}

body#contact-page section#landing h1 {
  max-width: 100%;
  line-height: 100px;
}

body#contact-page section#contact {
  padding-top: 100px;
}

body#contact-page section#contact .underline {
  border-bottom: 1px solid #e9e8e8;
  padding-bottom: 4px;
}

body#contact-page section#contact form#contact_form {
  max-width: clamp(300px, 75%, 800px);
  text-align: center;
  color: #c3e8bd;
  margin: 60px auto 0;
  padding-bottom: 100px;
}

body#contact-page section#contact form#contact_form.loading {
  opacity: .8;
}

body#contact-page section#contact form#contact_form.loading input, body#contact-page section#contact form#contact_form.loading textarea, body#contact-page section#contact form#contact_form.loading button {
  pointer-events: none;
}

body#contact-page section#contact form#contact_form.loading .loader {
  display: inline-flex;
}

body#contact-page section#contact form#contact_form #form_error {
  color: #912f3c;
}

body#contact-page section#contact form#contact_form .input_group {
  color: #e9e8e8;
  align-items: center;
  margin-top: 30px;
  display: flex;
}

body#contact-page section#contact form#contact_form .input_group label {
  min-width: 150px;
  text-align: left;
}

body#contact-page section#contact form#contact_form .input_group input, body#contact-page section#contact form#contact_form .input_group textarea {
  width: 100%;
  color: #fff;
  background-color: #7e807b;
  border: none;
  border-radius: 4px;
  padding: 12px 16px;
}

body#contact-page section#contact form#contact_form .input_group input:active, body#contact-page section#contact form#contact_form .input_group input:focus, body#contact-page section#contact form#contact_form .input_group textarea:active, body#contact-page section#contact form#contact_form .input_group textarea:focus {
  outline-color: #753235;
}

body#contact-page section#contact form#contact_form .input_group textarea {
  resize: vertical;
}

body#contact-page section#contact form#contact_form button {
  appearance: none;
  color: #fff;
  width: 150px;
  height: 42px;
  cursor: pointer;
  background-color: #7e807b;
  border: none;
  border-radius: 4px;
  outline: none;
  transition: all .3s ease-in-out;
  position: relative;
  overflow: hidden;
}

body#contact-page section#contact form#contact_form button:before {
  content: "";
  width: 100%;
  height: 100%;
  max-height: 0;
  max-width: 0;
  background-color: #753235;
  transition: all .3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
}

body#contact-page section#contact form#contact_form button:hover:before {
  max-height: 100px;
  max-width: 400px;
}

body#contact-page section#contact form#contact_form button span {
  z-index: 2;
  position: relative;
}

body#contact-page section#contact form#contact_form button .loader {
  display: none;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  body#contact-page section#landing .wide-container {
    padding: 0;
  }

  body#contact-page section#landing .wide-container > .image {
    top: 84px;
  }

  body#contact-page section#contact {
    padding-top: 60px;
  }

  body#contact-page section#contact p.text, body#contact-page section#contact .contact_hours, body#contact-page section#contact .form_title, body#contact-page section#contact form#contact_form, body#contact-page section#contact .submit {
    margin-top: 40px;
  }

  body#contact-page section#contact form#contact_form .input_group label {
    min-width: 130px;
  }
}

@media screen and (max-width: 576px) {
  body#contact-page section#landing h1 {
    font-size: 3.125rem;
    line-height: 3.125rem;
  }

  body#contact-page section#contact {
    padding-top: 40px;
  }

  body#contact-page section#contact p.text, body#contact-page section#contact .contact_hours, body#contact-page section#contact .form_title, body#contact-page section#contact form#contact_form, body#contact-page section#contact .submit {
    margin-top: 30px;
  }

  body#contact-page section#contact form#contact_form {
    padding-bottom: 50px;
  }

  body#contact-page section#contact form#contact_form .input_group {
    flex-direction: column;
    align-items: flex-start;
  }

  body#contact-page section#contact form#contact_form .input_group label {
    min-width: 130px;
  }

  body#contact-page section#contact form#contact_form .input_group input, body#contact-page section#contact form#contact_form .input_group textarea {
    width: auto;
    align-self: stretch;
  }
}

.burger {
  cursor: pointer;
  padding: 3em 1.5em;
  font-size: .875rem;
}

.burger.showHitArea {
  border: 1px solid olive;
}

.burger > div {
  width: 4em;
  height: .5em;
  background: #753235;
  border-radius: 8px;
}

.burger > div:before, .burger > div:after {
  content: "";
  width: 4em;
  height: .5em;
  background: #753235;
  border-radius: 8px;
  transition: font-size;
  display: block;
  position: absolute;
}

.burger > div:before {
  margin-top: -1.5em;
}

.burger > div:after {
  margin-top: 1.5em;
}

.burger-cross {
  transition: all .7s cubic-bezier(.68, -.35, .265, 1.35), font-size cubic-bezier(.68, -.35, .265, 1.35);
}

.burger-cross > div {
  transition: all .1s .3s, font-size;
}

.burger-cross > div:before, .burger-cross > div:after {
  transition: all .3s .2s, font-size;
}

.burger-cross.toggled {
  transform: rotate(180deg);
}

.burger-cross.toggled > div:before {
  transform: rotate(45deg)translate(1.1em, 1em);
}

.burger-cross.toggled > div {
  background: none;
}

.burger-cross.toggled > div:after {
  transform: rotate(-45deg)translate(1.1em, -1em);
}

.burger-arrow {
  transition: all .7s cubic-bezier(.68, 0, .265, 1.25), font-size cubic-bezier(.68, 0, .265, 1.25);
}

.burger-arrow > div {
  transition: all .5s, font-size;
}

.burger-arrow > div:before, .burger-arrow > div:after {
  transform-origin: 0 0;
  transition: all .4s .2s, font-size;
}

.burger-arrow.toggled {
  transform: rotate(180deg);
}

.burger-arrow.toggled > div:before {
  width: 3em;
  transform: rotate(-45deg)translate(-1.4em, 1.1em);
}

.burger-arrow.toggled > div:after {
  width: 3em;
  transform: rotate(45deg)translate(-1em, -1.2em);
}

header {
  height: 150px;
  z-index: 100;
  background-color: #585858;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

header .container {
  width: 100%;
}

header a.brand {
  width: clamp(150px, 17vw, 325px);
}

header #toggle-nav, header .burger-outer {
  display: none;
}

header nav {
  gap: 100px;
}

header nav .left {
  gap: 40px;
}

header nav .link {
  height: 100%;
  text-align: center;
  padding: 0;
  display: block;
  position: relative;
}

header nav .link:after {
  content: "";
  width: 100%;
  height: 2px;
  transform-origin: 0;
  background-color: #fff;
  border-radius: 16px;
  transition: all .3s ease-in-out;
  position: absolute;
  bottom: -4px;
  left: 0;
  transform: scale(0);
}

header nav .link.active:after {
  background-color: #753235;
}

header nav .link:hover:after, header nav .link.active:after {
  transform: scale(1);
}

@media screen and (max-width: 768px) {
  header {
    z-index: 200;
    height: 44px;
    background: none;
    padding: 20px 0;
    position: absolute;
  }

  header .container {
    align-items: flex-start;
  }

  header #toggle-nav {
    position: absolute;
  }

  header #toggle-nav:checked ~ nav {
    opacity: 1;
    transform: scale(1);
  }

  header .burger-outer {
    width: 44px;
    height: 44px;
    box-sizing: border-box;
    z-index: 999;
    background-color: #ffffffbf;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: 0;
    padding: 0;
    display: flex;
    position: fixed;
    top: 20px;
    right: 20px;
  }

  header .burger-outer .burger-container {
    transform: scale(.55);
  }

  header nav.row {
    opacity: 0;
    color: #e9e8e8;
    transform-origin: 100%;
    background-color: #191919;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    transition: all .5s, opacity .1s;
    display: flex;
    position: fixed;
    inset: 0;
    overflow: hidden;
    transform: scale(0);
  }

  header nav.row .left {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    display: flex;
  }

  header nav.row .brand-sp {
    width: clamp(150px, 24vw, 325px);
    margin-bottom: 60px;
    display: block;
  }

  header nav.row .link {
    padding-left: 0;
    font-size: 1.3125rem;
  }

  header nav.row .link:after {
    bottom: -2px;
  }

  header nav.row .sns {
    display: block;
  }

  header nav.row .sns > .row {
    flex-direction: column;
    align-self: center;
    margin-top: 30px;
    display: flex;
  }

  header nav.row .sns .sns-links {
    gap: 20px;
    margin-top: 16px;
  }

  header nav.row .sns .sns-links .sns-link {
    height: 22px;
  }

  header nav.row .sns .sns-links .sns-link svg {
    height: 100%;
    width: auto;
    display: block;
  }
}

.breadcrumbs {
  z-index: 100;
  background-color: #fff;
  margin: auto;
  padding: 30px 0;
  position: relative;
}

.breadcrumbs .separator {
  margin: 0 8px;
}

.breadcrumbs .breacrumb {
  position: relative;
}

.breadcrumbs .breacrumb:last-of-type {
  color: #753235;
  pointer-events: none;
}

.breadcrumbs .breacrumb:after {
  content: "";
  width: 100%;
  height: 2px;
  transform-origin: 0;
  background-color: #191919;
  border-radius: 2px;
  transition: all .3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scale(0);
}

.breadcrumbs .breacrumb:hover {
  color: #191919;
}

.breadcrumbs .breacrumb:hover:after {
  transform: scale(1);
}

footer {
  z-index: 999999;
  background-color: #191919;
  position: relative;
}

footer .grid {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

footer .left, footer .right {
  padding: 40px 60px;
}

footer .left {
  background-color: #7e807b;
}

footer .left a.contact {
  display: block;
  position: relative;
}

footer .left a.contact .arrow {
  width: 12px;
  transform-origin: bottom;
  position: absolute;
  bottom: 0;
  left: 80px;
  transform: rotate(-90deg);
}

footer .left a.contact .arrow svg {
  width: 100%;
  height: auto;
  display: block;
}

footer .left a.contact .arrow svg path {
  stroke: #fff;
}

footer .left a.brand {
  width: clamp(150px, 17vw, 325px);
  display: block;
}

footer .left .sns span {
  margin-right: 30px;
}

footer .left .sns .sns-links {
  gap: 24px;
}

footer .left .sns .sns-link {
  height: 22px;
}

footer .left .sns .sns-link svg {
  height: 100%;
  width: auto;
  display: block;
}

footer .right {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

footer .right:after {
  content: "";
  background-color: #19191933;
  position: absolute;
  inset: 0;
}

footer .right .footer-nav-bg {
  z-index: 0;
  position: absolute;
  inset: 0;
}

footer .right .footer-nav-bg .image {
  width: 100%;
  height: 100%;
}

footer .right nav {
  z-index: 999;
  flex: 1;
  gap: 100px;
  position: relative;
}

footer .right nav .link {
  margin: 20px 0;
}

footer .copyright, footer .privacy {
  display: block;
}

@media screen and (max-width: 768px) {
  footer {
    z-index: 99;
    background-color: #191919;
    position: relative;
  }

  footer .grid {
    flex-direction: column-reverse;
    padding: 0;
    display: flex;
  }

  footer .left {
    padding-bottom: 60px;
  }

  footer .right {
    background-position: bottom;
  }

  footer .right nav {
    width: 100%;
    justify-content: space-between;
  }

  footer .copyright {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 10px;
    left: 0;
  }
}

/*# sourceMappingURL=index.02a05b5a.css.map */
