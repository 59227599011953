footer
  position: relative
  z-index: 999999
  background-color: $color-primary
  .grid
    display: grid
    grid-template-columns: 1fr 1fr
  .left, .right
    padding: $margin-lg $margin-xxl
  .left
    background-color: $color-grey
    a.contact
      display: block
      position: relative
      .arrow
        position: absolute
        width: 12px
        bottom: 0
        left: 80px
        transform: rotate(-90deg)
        transform-origin: bottom
        svg
          display: block
          width: 100%
          height: auto
          path
            stroke: $color-white

    a.brand
      display: block
      width: clamp(150px, 17vw, 325px)

    .sns
      span
        margin-right: $margin-md
      .sns-links
        gap: $spacing-xl
      .sns-link
        height: 22px
        svg
          display: block
          height: 100%
          width: auto

  .right
    display: flex
    flex-direction: column
    align-items: flex-start
    position: relative
    &::after
      position: absolute
      content: ''
      inset: 0
      background-color: rgba($color-primary, 0.2)
    .footer-nav-bg
      position: absolute
      inset: 0
      z-index: 0
      .image
        width: 100%
        height: 100%
    nav
      flex: 1
      gap: $margin-max
      position: relative
      z-index: 999
      .link
        margin: $margin-sm 0


  .copyright, .privacy
    display: block

@media screen and (max-width: $breakpoints-sm)
  footer
    position: relative
    z-index: 99
    background-color: $color-primary
    .grid
      display: flex
      flex-direction: column-reverse
      padding: 0

    .left
      padding-bottom: $margin-xxl
    .right
      background-position: bottom
      nav
        width: 100%
        justify-content: space-between

    .copyright
      position: absolute
      bottom: $spacing-xs
      left: 0
      width: 100%
      text-align: center

@media screen and (max-width: $breakpoints-xs)
